
.page-list{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
    .page-section-main {
        width: 80%;
        display: grid;
        gap: 20px;
        margin-top: 3%;
        display: grid;
        grid-template-columns: repeat(var(--grid-columns), 1fr);
    }
}