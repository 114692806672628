.page-title {
  backdrop-filter: blur(30px);
  margin-top: 2%;
  margin-bottom: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 7%;
}

.backdrop-blur {
  backdrop-filter: blur(30px);
}

.appear-from-left {
  animation: appear-from-left $page-transition-duration;
}

.appear-from-right {
  animation: appear-from-right $page-transition-duration;
}

.appear-from-bottom {
  animation: appear-from-bottom $page-transition-duration;
}

.disappear-to-left {
  animation: disappear-to-left $page-transition-duration;
}
