@font-face {
  font-family: 'SegoeUI';
  src: local('Segoe UI'), url('../fonts/SegoeUI/SegoeUI.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'SegoeUI';
  src: local('Segoe UI'), url('../fonts/SegoeUI/SegoeUI-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'GemunuLibre';
  src: url('../fonts/GemunuLibre/GemunuLibre-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}
