@import '../../styles/variables';
@import '../../styles/mixins';

.d-main-layout {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: black;
  overflow: hidden;

  .scrollbar {
    height: 100vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;

    .d-content {
      width: 100%;
      flex: 1;
      // padding-top: $header-h;
      display: flex;

      & > div {
        flex: 1;
      }

      @include breakpoint($sm) {
        padding-top: $header-mobile-h;
      }
    }
  }
}
